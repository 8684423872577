import { Heading, OnlyDesktop, OnlyMobile } from 'components/atoms';
import {
  MembershipAddonPanel,
  MembershipCredentialPanel,
  MembershipSectionPanel,
  MembershipTierPanel,
  ModalApplicationRedirect,
  ModalApplyMembership,
  ModalError,
  ModalEthicsAndFirmBillingInvite,
  ModalFirmBillingFLPRequirementsNotMet,
  ModalUpgradeMembership,
  ModalUpgradingMembership,
} from 'components/organisms';
import { RetiredQualificationModal } from 'components/molecules/ApplicationPersonalForm/RetiredQualificationModal';
import { MiniUserWidgetContainer } from 'containers/UserWidgetContainer';
import moment from 'moment-timezone';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { generatePath } from 'react-router-dom';

import {
  IC_ACCORDION_DOWN,
  IC_ACCORDION_LEFT,
  IC_ACCORDION_UP,
  IC_CANCEL_GRAY,
  IC_USERS,
  IC_USERS_PURPLE,
  IC_AWARD,
  IC_LAYERS,
  IC_PACKAGE,
  IC_AWARD_PURPLE,
  IC_LAYERS_PURPLE,
  IC_PACKAGE_PURPLE,
} from 'resources/images';
import { ReactComponent as AicpaCimaLogoWhite } from 'resources/images/aicpa-cima-logo-white.svg';
import { ReactComponent as AicpaCimaLogoWhiteMobile } from 'resources/images/aicpa-cima-logo-white-mobile.svg';
import { Button, ButtonEnums } from 'components/atoms/Button/Button';
import { Accordion, AccordionTitleProps, Container, Grid, Icon, Loader, Popup } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { CurrentJourney, MembershipPackageAccordionStatus, Routes, StorageNames } from '../../../constants';
import { MembershipTypeSelection } from 'components/molecules/MembershipTypeSelection/MembershipTypeSelection';
import { cartSelector, cartCredentialsSelector } from 'modules/cart/selectors';
import { getMembershipPackagePath, getPath, areAllTruthy, hasTruthyValue } from 'utils';
import { ADD_CART, ADD_CART_FIRST, EVENT_CLICK, handleEvent } from 'utils/Analytics';
import {
  CartCompoundAnalytics,
  getOrderTypeAnalytics,
  isB2BMembershipType,
  isB2CMembershipType,
} from 'utils/Analytics/helpers';

import {
  addMembershipToCart,
  fetchDataOfSpecificInvite,
  fetchMembershipTypes,
  setAddCartLoading,
  setMembershipJourneyType,
  setMembershipPackageType,
} from 'modules/membership';

import { CimaNotInvitedMember } from 'components/App/lazy-imports';
import { ApplicationProgressRoutesMap } from 'constants/index';
import { useApplicationProgress } from 'hooks/useApplicationProgress';
import { CONSTANTS } from 'modules/app/constants';
import { getConstantByKeySelector } from 'modules/app/selectors';
import { setCheckoutPage } from 'modules/checkout/actions';

import { goToPreviousRoute } from 'modules/layouts';
import {
  addMembershipPackageCredential,
  addMembershipPackageSectionWithPriceProduct,
  resetUserChoiceSlug,
  setCredentialsItemRenewals,
  setHasSelectedAddOns,
  setHasSelectedCredential,
  setHasSelectedSection,
  setHasSelectedType,
  setInviteDataStatus,
  setMembershipEvent,
  setSectionProductId,
} from 'modules/membership/actions';
import {
  addCartLoadingSelector,
  currentMembershipProduct,
  existingCredentialSelector,
  existingSectionSelector,
  hasExistingCredentialSelector,
  hasExistingSectionSelector,
  isCimaRenewalSeasonSelector,
  isComingFromPropPageSelector,
  isCredentialsJourneySelector,
  isFLPSwitchSelector,
  isFLPUpgradeSelector,
  isRenewalSeasonSelector,
  isRenewalsJourneySelector,
  membershipEligibleCredentialSelector,
  membershipEligibleSectionSelector,
  membershipEventSelector,
  membershipInviteDataSelector,
  membershipPropPageURLSelector,
  membershipRenewalsSelector,
  membershipSectionSelector,
  membershipSubscriptionsFetchedSelector,
  membershipTypesListSelector,
  membershipTypesSelector,
  sectionsCredentialsRenewalSelector,
  userMembershipCredentialSelector,
  userMembershipPackageSelector,
  userMembershipSectionProductIdSelector,
  userMembershipTierSelector,
  isMembershipJourneySelector,
} from 'modules/membership/selectors';
import { setApplicationInitialLoad, updateMembershipApplicationPartTier } from 'modules/postLoginActionsTable';
import { getProductsListData } from 'modules/products/actions';
import { freeTrialEndDateSelector, hasFcmaCredentialProductSelector } from 'modules/products/selectors';
import { getInviteIdSelector } from 'modules/startup/selectors';
import {
  applicationSelector,
  currentJourneyLearningPathwaySelector,
  customerCredentialsSelector,
  customerInactiveCredentialsSelector,
  customerInactiveMembershipsSelector,
  customerInactiveSectionsSelector,
  customerMembershipsSelector,
  customerProfileFetchedSelector,
  customerSectionsSelector,
  employersWithEmployeeRelationshipSelector,
  ethicsCaseSelector,
  examsPassSelector,
  firmBillingInviteSelector,
  hasFirmBillingRelationSelector,
  isUserMemberSelector,
  isUserMemberSuspendedSelector,
  personAccountDataSelector,
  userEmailSelector,
  isUserMemberLapsedSelector,
  userHasEthicsViolationSelector,
  primaryEmployerSelector,
  isAicpaMemberSelector,
} from 'modules/user/selectors';
import { Checkout, MembershipTypes, Product, Salesforce, User, Firm } from 'mxp-schemas';
import { setLocalStorageItem } from 'utils/localStorage';
import { featureTogglesSelector } from 'modules/featureToggle/selectors';
import { PageMembershipHeader } from './PageMembershipHeader';
import { PageRenewYourMembership } from '../PageRenewYourMembership/PageRenewYourMembership';
import { MembershipAccordionRenderer } from 'components/organisms/MembershipAccordionRenderer/MembershipAccordionRenderer';
import { conditionalFunction } from 'mxp-utils/dist/lib/user';

// Custom Accordion Panel (MOVE) -----------------------

export const eligibilityExamChecker = (
  userChoiceMembershipCredential: State.UserChoiceCredential[],
  examsPassedByUser: State.ExamResponse[]
): { headerText: string; bodyText: string; productId: string; hasErrorInCredentials: boolean } => {
  const response = {
    headerText: '',
    bodyText: '',
    productId: '',
    hasErrorInCredentials: false,
  };
  userChoiceMembershipCredential?.forEach(c => {
    const overlap: any = [];
    const overlapAlternatives: any = [];

    examsPassedByUser?.forEach(e => {
      if (c?.eligibleExams !== '') {
        c?.eligibleExams?.forEach((eligibleExam: any) => {
          // TODO: need to confirm if it's SFS_EXA_ExamName__c or Name field
          if (eligibleExam['en-US'] === e.SFS_EXA_ExamName__c) overlap.push(e);
        });
      }
      if (c?.alternativeExams !== '') {
        c?.alternativeExams?.forEach((alternativeExam: any) => {
          // TODO: need to confirm if it's SFS_EXA_ExamName__c or Name field
          if (alternativeExam['en-US'] === e.SFS_EXA_ExamName__c) overlapAlternatives.push(e);
        });
      }
    });

    if (overlap.length < Number(c?.num_of_exams)) {
      // check if has alternativeExams, if yes continue if false then blocked the user
      if (c?.alternativeExams === '' || c?.alternativeExams?.length === 0) {
        // if there's no alternative exams set in CT then blocked
        response.hasErrorInCredentials = true;
        response.headerText = 'You are not eligible';
        response.bodyText = `You are not eligible for the ${c?.pathway_name} as you have not passed the ${c?.pathway_name} exams.`;
        response.productId = c?.productId;
      } else {
        // if there's alternative exams set in CT check if the user pass all of it
        if (overlapAlternatives.length < Number(c?.alternativeExams?.length)) {
          response.hasErrorInCredentials = true;
          response.headerText = 'You are not eligible';
          response.bodyText = `You are not eligible for the ${c?.pathway_name} as you have not passed the ${c?.pathway_name} exams.`;
          response.productId = c?.productId;
        }
      }
    }
  });

  return response;
};

// TODO: Move to own component
export const MembershipTypePanel: React.FC<{ hideInfoBubble?: boolean; shouldShowNewPackageBuilder?: boolean }> = memo(
  ({ hideInfoBubble, shouldShowNewPackageBuilder }) => {
    useApplicationProgress(Product.MembershipApplicationType.AICPA);
    const dispatch = useDispatch();
    const history = useHistory();
    const { list } = useSelector(membershipTypesSelector);
    const isUserMember = useSelector(isUserMemberSelector);
    const [isModalOpen, setModalOpen] = useState(false);
    const isUserMemberSuspended = useSelector(isUserMemberSuspendedSelector);
    const personalDetail = useSelector(personAccountDataSelector);

    const userDiffAge = moment().diff(personalDetail.birthDate, 'years');
    const isUserRetired = moment(personalDetail.birthDate).isValid() && userDiffAge > 64;

    const handleSelectType = useCallback(
      (id: string, typeSlug: string) => {
        if (typeSlug === User.B2BMembershipTypes.RETIRED && !isUserRetired) {
          setModalOpen(true);
          return;
        }

        history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Tier));
        dispatch(setMembershipPackageType(id, typeSlug));
        dispatch(setHasSelectedType(true));
      },
      [history, dispatch, isUserRetired]
    );

    const handleCloseModal = () => {
      setModalOpen(false);
    };

    return (
      <div>
        <RetiredQualificationModal retiredModalOpen={isModalOpen} onClose={handleCloseModal} />
        <OnlyDesktop>
          <StyledMembershipInfo>
            Please select your preferred account and membership type to continue
          </StyledMembershipInfo>
        </OnlyDesktop>
        <StyledGrid>
          <Grid.Column>
            <StyledTypeContainer>
              <MembershipTypeSelection
                products={list}
                selectMembership={handleSelectType}
                isUserMember={isUserMember || isUserMemberSuspended}
                shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
              />
            </StyledTypeContainer>
          </Grid.Column>
        </StyledGrid>
      </div>
    );
  }
);

// Accordion Panel List -----------------------
export interface MembershipPackageAccordionItemProps {
  key: string;
  title: string;
  content: {
    content: React.ReactNode;
  };
  pillValue?: string;
  showInFlpRenewals?: boolean;
  showInUpgrade?: boolean;
  popupContent?: string;
  icon?: any;
  secondaryIcon?: any;
}

interface MembershipPackageAccordionProps {
  data: MembershipPackageAccordionItemProps[];
  activeIndex: number;
  maxIndex: number;
  onClick: AccordionTitleProps['onClick'];
  isCenterMembershipRenewal?: boolean;
  isCimaMembership?: boolean;
  isCenterMembershipJourney?: boolean;
  centerRenewalIssuer?: string;
  isCAQType?: boolean;
  onClickCancelCTA?: any;
  onClickProceedCTA?: () => void;
  currentAccordion?: any;
}

export const MembershipPackageAccordion: React.FC<MembershipPackageAccordionProps> = ({
  data,
  activeIndex,
  maxIndex,
  onClick,
  isCenterMembershipRenewal = false,
  isCimaMembership = false,
  isCenterMembershipJourney,
  centerRenewalIssuer,
  isCAQType,
  onClickCancelCTA,
  onClickProceedCTA,
  currentAccordion,
}) => {
  const isFLPUpgrade = useSelector(isFLPUpgradeSelector);
  const isFLPSwitch = useSelector(isFLPSwitchSelector);
  const currentJourneyLearningPathway = useSelector(currentJourneyLearningPathwaySelector);
  const isCenterMembershipJourneyNotEmpty = Boolean(isCenterMembershipJourney);
  const isRenewalsJourney = useSelector(isRenewalsJourneySelector);
  const userChoice = useSelector(userMembershipPackageSelector);

  const { useNewSignUpOnly, useNewMembershipAICPA } = useSelector(featureTogglesSelector);

  const shouldShowNewPackageBuilder = useNewSignUpOnly;

  const popupTrigger = () => (
    <div style={{ display: 'inline-block' }}>
      <StyledIcon name="info circle" />
    </div>
  );
  const toolTipContent = (content: string) => (
    <div>
      <p>{content}</p>
    </div>
  );

  const existingAccordionContent = (
    <>
      {(isFLPSwitch || isFLPUpgrade) && <StyledDivFLP />}
      <StyledUl
        index={data.length}
        activeIndex={activeIndex}
        isCenterMembershipJourney={isCenterMembershipJourneyNotEmpty}
        isRenewalsJourney={isRenewalsJourney}
      >
        {data.map(({ key, title, content, pillValue, popupContent }, i) => {
          // This Condition is for Slug Pill value, some Slug Pill value is undefined and null.

          let pill = activeIndex === 0 && pillValue !== '0' ? pillValue : pillValue === undefined ? '0' : pillValue; // tslint:disable-line
          if ((activeIndex === 0 && key === 'section') || (key === 'credentials' && pillValue === '0')) pill = '1';
          if (activeIndex < i) pill = '';
          return (
            <React.Fragment key={key}>
              <StyledAccordionHeaderContainer>
                <StyledNumbering>{!isCenterMembershipRenewal && <>{i + 1}</>}</StyledNumbering>
                <StyledAccordionTitle
                  id={i === 1 && `second-item`}
                  className={i <= 1 && `white-divider`}
                  activeIndex={activeIndex}
                  active={activeIndex === i}
                  index={i}
                  onClick={isFLPUpgrade && key === MembershipPackageAccordionStatus.Level ? null : onClick}
                >
                  <StyledAccordionTitleContainer>
                    <StyledSpan>
                      {popupContent && (
                        <StyledPopup
                          content={toolTipContent(popupContent)}
                          key="icon"
                          trigger={popupTrigger()}
                          hoverable
                          hideOnScroll
                        />
                      )}
                      {title}
                    </StyledSpan>
                    <StyledHeaderAccordionIcon src={activeIndex === i ? IC_ACCORDION_UP : IC_ACCORDION_DOWN} />
                  </StyledAccordionTitleContainer>
                  <StyledLi
                    changeColor={isCimaMembership}
                    isItemDisabled={isFLPUpgrade && key === MembershipPackageAccordionStatus.Level}
                  />
                  {activeIndex === i && (
                    <>
                      {isCenterMembershipRenewal && (
                        <InfoPillDiv hasCenterRenewalIssuer={Boolean(isCAQType)}>
                          {Boolean(isCAQType) && (
                            <InfoPill isCenterMembershipRenewal={isCenterMembershipRenewal} index={data.length}>
                              {centerRenewalIssuer} Issuer audits
                            </InfoPill>
                          )}
                          <InfoPill isCenterMembershipRenewal={isCenterMembershipRenewal} index={data.length}>
                            {pill} CPAs
                          </InfoPill>
                        </InfoPillDiv>
                      )}
                      <StyledDivider activeIndex={activeIndex} index={i} changeColor={isCimaMembership} />
                    </>
                  )}
                  {i !== activeIndex && i < maxIndex && pill !== '' && pill !== undefined && (
                    <>
                      <InfoPill
                        isFlpPackage={
                          currentJourneyLearningPathway === MembershipTypes.Pathway.FLP &&
                          (userChoice.type.slug === MembershipTypes.CimaMembershipProductSlug.CIMA_CANDIDATE_PRODUCT ||
                            userChoice.type.slug ===
                              MembershipTypes.CimaMembershipProductSlug.CIMA_AFFILIATE_PRODUCT) &&
                          Boolean(pill) &&
                          // pillValue!.length > 20 &&
                          i === 1
                        }
                        index={data.length}
                      >
                        <StyledSpanPillOutSide>
                          <StyledSpanChoose>{pill}</StyledSpanChoose>
                          <StyledSpanPill>
                            <StyledHeaderSlugCloseIcon src={IC_CANCEL_GRAY} />
                          </StyledSpanPill>
                        </StyledSpanPillOutSide>
                      </InfoPill>
                    </>
                  )}
                </StyledAccordionTitle>
              </StyledAccordionHeaderContainer>
              {activeIndex === i && (
                <>
                  <StyledAccordionContent active={activeIndex === i}>
                    {Boolean(isCenterMembershipJourney) && activeIndex === 0 && (
                      <StyledCenterDescription>
                        Please select one center, then click apply. You will have the opportunity to apply for another
                        center after you complete the application.
                      </StyledCenterDescription>
                    )}
                    {content?.content && typeof content.content !== 'string' && typeof content.content !== 'number'
                      ? React.cloneElement(content.content as any, { index: i, activeIndex: i })
                      : content.content}
                  </StyledAccordionContent>
                </>
              )}
            </React.Fragment>
          );
        })}
      </StyledUl>
    </>
  );

  const destinationURL = useNewMembershipAICPA
    ? getPath(Routes.APPLICATION_FORM_PERSONAL)
    : getPath(Routes.APPLICATION_FORM_START);
  const isAddonsComponent = currentAccordion === MembershipPackageAccordionStatus.Addons;
  const newAccordionContent = (
    <>
      {data.map(({ key, title, content, popupContent, icon, secondaryIcon }, i) => {
        return (
          <>
            <MembershipAccordionRenderer
              accordionKey={key}
              title={title}
              content={
                content?.content && typeof content.content !== 'string' && typeof content.content !== 'number'
                  ? React.cloneElement(content.content as any, { index: i, activeIndex: i })
                  : content.content
              }
              index={i}
              icon={icon}
              secondaryIcon={secondaryIcon}
              isActive={activeIndex === i}
              activeIndex={activeIndex}
              onClickCallback={onClick}
              toolTipContent={toolTipContent}
              popupTrigger={popupTrigger}
              popupContent={popupContent}
            />
            <br />
          </>
        );
      })}
      <ActionButtonsContainer>
        <Button
          to={destinationURL}
          onClick={onClickProceedCTA}
          size={ButtonEnums.sizes.medium}
          variant={ButtonEnums.variants.primary}
          testId="package-builder-proceed-button"
          disabled={!isAddonsComponent}
        >
          Proceed with profile setup
        </Button>
        <Button
          onClick={onClickCancelCTA}
          size={ButtonEnums.sizes.medium}
          variant={ButtonEnums.variants.secondary}
          testId="package-builder-cancel-button"
        >
          Cancel
        </Button>
      </ActionButtonsContainer>
    </>
  );

  const [showRenewalMembershipPage, setShowRenewalMembershipPage] = useState<boolean>(isRenewalsJourney);
  const changeToUpdateProcess = (): void => setShowRenewalMembershipPage(!showRenewalMembershipPage);

  return (
    <StyledContainer shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}>
      {!useNewSignUpOnly ? (
        <Accordion>{existingAccordionContent}</Accordion>
      ) : isRenewalsJourney ? (
        <PageRenewYourMembership changeToUpdateProcess={changeToUpdateProcess} />
      ) : (
        newAccordionContent
      )}
    </StyledContainer>
  );
};

export const PageMembershipPackage: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [maxIndex, setMaxIndex] = useState(-1);
  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const cart = useSelector(cartSelector);
  const freeTrialEndDate = useSelector(freeTrialEndDateSelector);
  const pageSubHeader = useSelector(getConstantByKeySelector(CONSTANTS.MEMBERSHIP.PACKAGE_PAGE.SUB_HEADER));
  const userChoice = useSelector(userMembershipPackageSelector);
  const hasFirmBillingRelation = useSelector(hasFirmBillingRelationSelector);
  const customerMemberships = useSelector(customerMembershipsSelector);
  const customerMembershipTerm = customerMemberships?.[0]?.membershipTerm || {};
  const customerInactiveMemberships = useSelector(customerInactiveMembershipsSelector);
  // const hasActiveQuoteFromFirm = useSelector(hasActiveQuoteFromFirmSelector);
  const ethicsStatusSelector = useSelector(ethicsCaseSelector);
  const hasFirmBillingInvite = useSelector(firmBillingInviteSelector);
  const employersWithEmployeeRelationship = useSelector(employersWithEmployeeRelationshipSelector);
  const isCredentialsJourney = useSelector(isCredentialsJourneySelector);
  const isComingFromPropPage = useSelector(isComingFromPropPageSelector);
  const addCartLoading = useSelector(addCartLoadingSelector);

  const membershipEvent = useSelector(membershipEventSelector);
  // user choice
  const userMembershipSectionProductId = useSelector(userMembershipSectionProductIdSelector);
  const membershipSection = useSelector(membershipSectionSelector);
  const membershipRenewals = useSelector(membershipRenewalsSelector);
  const personAccountData = useSelector(personAccountDataSelector);

  // renewals journey
  const isRenewalsJourney = useSelector(isRenewalsJourneySelector);
  const customerInactiveCredentials = useSelector(customerInactiveCredentialsSelector);
  const customerInactiveSections = useSelector(customerInactiveSectionsSelector);
  const existingSection = useSelector(existingSectionSelector);
  const existingCredential = useSelector(existingCredentialSelector);

  const isFLPSwitch = useSelector(isFLPSwitchSelector);
  const isFLPUpgrade = useSelector(isFLPUpgradeSelector);

  const credentialsActive = useSelector(customerCredentialsSelector);
  const sectionsActive = useSelector(customerSectionsSelector);
  const ifSectionEligibleToRenew = sectionsActive.filter(
    section => moment(section.endDate).year() <= moment().add(1, 'y').year()
  );
  const ifCredentialEligibleToRenew = credentialsActive.filter(
    credential => moment(credential.endDate).year() <= moment().add(1, 'y').year()
  );
  const [activeCredentials, setActiveCredentials]: any = useState([]);
  const [activeSections, setActiveSections]: any = useState([]);
  const [isLocationChange, setLocationChange]: any = useState(false);

  const checkIfNotAlreadyRenewed = useCallback(
    (date: string) => {
      let currentYear = moment().year();
      if (membershipEvent.isClickedCredentialsSectionsRenewal || membershipEvent.isClickedMembershipRenewal) {
        currentYear = moment().add(1, 'y').year();
        return moment(date).year() <= currentYear;
      }
      const isSameYear = moment(date).year() === currentYear;

      // If from prop page and first time purchase section
      if ((membershipEvent.isClickedSectionsJourney || isCredentialsJourney) && isSameYear) {
        return false;
      }

      return isSameYear;
    },
    [membershipEvent, isCredentialsJourney]
  );

  useEffect(() => {
    // credentials
    let activeCredentialsArray: any = [];

    // sections
    let activeSectionsArray: any = [];

    // NOTE: existingCredential is only returning Active LineItems from zuora so no need to check in SF if its lapsed here.
    existingCredential.forEach(
      (credential: { variant: { sku: string }; zuoraTermEndDate: string; subscriptionStatus: string }) => {
        if (checkIfNotAlreadyRenewed(credential?.zuoraTermEndDate)) {
          activeCredentialsArray = [...activeCredentialsArray, credential];
        }
      }
    );

    // NOTE: existingSection is only returning Active LineItems from zuora so no need to check in SF if its lapsed here.
    existingSection.forEach((section: { variant: { sku: string }; zuoraTermEndDate: string }) => {
      if (checkIfNotAlreadyRenewed(section?.zuoraTermEndDate)) {
        activeSectionsArray = [...activeSectionsArray, section];
      }
    });
    setActiveSections(activeSectionsArray);
    setActiveCredentials(activeCredentialsArray);
  }, [
    existingCredential,
    customerInactiveCredentials,
    existingSection,
    customerInactiveSections,
    checkIfNotAlreadyRenewed,
  ]);

  useEffect(() => {
    // sections
    if (userMembershipSectionProductId) {
      const selectedSection = membershipSection?.listOfProductWithPrice?.find(
        (sect: any) => sect.productId === userMembershipSectionProductId
      );
      const sectionIsRenewed = existingSection.some(
        (section: { variant: { sku: string }; zuoraTermEndDate: string }) =>
          section?.variant?.sku === selectedSection?.variants?.[0]?.sku &&
          !checkIfNotAlreadyRenewed(section?.zuoraTermEndDate)
      );
      if (selectedSection && !sectionIsRenewed) {
        dispatch(
          addMembershipPackageSectionWithPriceProduct(
            userMembershipSectionProductId,
            selectedSection?.variants?.[0]?.sku
          )
        );
        dispatch(setSectionProductId(''));
      }
    }
  }, [
    userMembershipSectionProductId,
    membershipSection,
    dispatch,
    existingSection,
    checkIfNotAlreadyRenewed,
    membershipEvent,
  ]);

  const userMembershipTier = useSelector(userMembershipTierSelector);
  const currentAccordion = useMemo(() => {
    return location.hash.replace('#', '');
  }, [location]);

  useEffect(() => {
    if (
      membershipRenewals?.credentials?.productId &&
      currentAccordion === MembershipPackageAccordionStatus.Credentials &&
      userMembershipTier
    ) {
      dispatch(
        addMembershipPackageCredential(membershipRenewals?.credentials?.productId, membershipRenewals?.credentials?.sku)
      );
      dispatch(setCredentialsItemRenewals('', ''));
    }
  }, [membershipRenewals, dispatch, currentAccordion, userMembershipTier]);
  // credentials pathway journey
  const examsPassedByUser = useSelector(examsPassSelector);
  const userChoiceMembershipCredential = useSelector(userMembershipCredentialSelector); // userChoice
  const sectionsCredentialsRenewal = useSelector(sectionsCredentialsRenewalSelector);
  const membershipPropPageURL = useSelector(membershipPropPageURLSelector);
  const { useNewSignUpOnly, useNewMembershipAICPA } = useSelector(featureTogglesSelector);
  const isMembershipJourney = useSelector(isMembershipJourneySelector);
  const shouldShowNewPackageBuilder = useNewSignUpOnly && isMembershipJourney;
  const [hasErrorInCredentials, setHasErrorInCredentials] = useState(false);
  const [errorCredentialProps, setErrorCredentialProps] = useState({
    headerText: '',
    bodyText: '',
    productId: '',
  });

  const [isNoInviteData, setIsNoInviteData] = useState(false);
  const [firmBillingErrorMessage, setFirmBillingErrorMessage] = useState('');

  useEffect(() => {
    const { hasErrorInCredentials: errorInCred, ...others } = eligibilityExamChecker(
      userChoiceMembershipCredential,
      examsPassedByUser
    );
    if (errorInCred) {
      setHasErrorInCredentials(errorInCred);
      setErrorCredentialProps({ ...others });
    }
  }, [examsPassedByUser, userChoiceMembershipCredential]);

  const handleCloseCredentialsErrorModal = useCallback(() => {
    setHasErrorInCredentials(false);

    if (!useNewSignUpOnly) {
      // push to prop page then refresh redux state
      if (membershipPropPageURL?.credential || membershipPropPageURL?.variant) {
        history.push(membershipPropPageURL?.credential || membershipPropPageURL?.variant);
      } else {
        dispatch(goToPreviousRoute());
      }

      window.location.reload();
    }
  }, [dispatch, history, membershipPropPageURL, useNewSignUpOnly]);

  const isRenewalSeason = useSelector(isRenewalSeasonSelector);
  const [showQuoteStatusAndIsMembershipPaidByFirmModal, setShowQuoteStatusAndIsMembershipPaidByFirmModal] =
    useState(hasFirmBillingRelation);
  const customerProfileFetched = useSelector(customerProfileFetchedSelector);
  const userHasEthicsViolation = useSelector(userHasEthicsViolationSelector);
  const application = useSelector(applicationSelector);
  const isUserMember = useSelector(isUserMemberSelector);
  const membershipSubscriptionsFetched = useSelector(membershipSubscriptionsFetchedSelector);
  const currentMembership = useSelector(currentMembershipProduct);

  const inviteId = useSelector(getInviteIdSelector);
  const inviteData = useSelector(membershipInviteDataSelector);
  const hasExistingCredential = useSelector(hasExistingCredentialSelector);
  const hasCredentialInCart = useSelector(cartCredentialsSelector)?.length || false;
  const isAicpaMember = useSelector(isAicpaMemberSelector);
  const hasExistingSection = useSelector(hasExistingSectionSelector);
  const { isPaidByFirm } = inviteData;
  const eligibleCredential = useSelector(membershipEligibleCredentialSelector);
  const eligibleSection = useSelector(membershipEligibleSectionSelector);
  const isCimaRenewalSeason = useSelector(isCimaRenewalSeasonSelector);
  const isUserMemberLapsed = useSelector(isUserMemberLapsedSelector);
  const primaryEmployer = useSelector(primaryEmployerSelector);
  const [isMembershipTypesLoading, setIsMembershipTypesLoading] = useState(false);
  const [message, setMessage] = useState(
    'Please contact your Firm Admin as you are unable to progress as you do not qualify for this invite'
  );
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [isInviteDataLoading, setIsInviteDataLoading] = useState(Boolean(inviteId));

  useEffect(() => {
    if (!currentMembership?.membership) {
      dispatch(setMembershipJourneyType(MembershipTypes.MembershipJourneyType.MEMBERSHIP_SIGN_UP));
    }
  }, [currentMembership]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      setIsMembershipTypesLoading(true);
      try {
        if (membershipSubscriptionsFetched) {
          await dispatch(getProductsListData());
        }
        await dispatch(fetchMembershipTypes());
        setIsMembershipTypesLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [membershipSubscriptionsFetched]); // eslint-disable-line react-hooks/exhaustive-deps

  //
  const membershipTypesList = useSelector(membershipTypesListSelector);
  const isFCMACredential = useSelector(hasFcmaCredentialProductSelector);
  const userEmail = useSelector(userEmailSelector);

  const [isNotAllowedForMembershipTypes, setIsNotAllowedForMembershipTypes] = useState(false);
  const handleCloseModal = useCallback(() => {
    setIsNotAllowedForMembershipTypes(false);
  }, []);

  useEffect(() => {
    if (!!membershipTypesList?.length && userChoice.type.slug) {
      const ifExist = membershipTypesList.some((list: any) => list.slug === userChoice.type.slug);
      if (!ifExist) setIsNotAllowedForMembershipTypes(true);
    }
  }, [userChoice, membershipTypesList]);

  useEffect(() => {
    if (Boolean(inviteId)) {
      dispatch(setAddCartLoading(true));
      setLocalStorageItem({ [StorageNames.inviteId]: inviteId });
      setLocalStorageItem({ [StorageNames.inviteEmail]: userEmail });
      dispatch(fetchDataOfSpecificInvite(inviteId)).then(async (response: any) => {
        dispatch(setAddCartLoading(false));
        setIsInviteDataLoading(false);

        if (response.payload.error === Firm.InviteErrors.NOT_OWNED) {
          setIsNoInviteData(true);
          setFirmBillingErrorMessage(Firm.FirmBillingErrorMessage.INVITE_NOT_OWNED);
          return;
        }
        if (response?.payload?.status === User.InviteStatus.ACCEPTED) {
          dispatch(setInviteDataStatus(User.InviteStatus.ACCEPTED));
          setIsNoInviteData(true);
          setFirmBillingErrorMessage(Firm.FirmBillingErrorMessage.ALREADY_ACCEPTED);
          return;
        }

        if (!response?.payload?.inviteId) {
          // if there's no inviteId found
          dispatch(setInviteDataStatus('Removed'));
          setIsNoInviteData(true);
          setFirmBillingErrorMessage(Firm.FirmBillingErrorMessage.ACCESS_DENIED);
        }
      });
    }
  }, [dispatch, inviteId, userEmail, employersWithEmployeeRelationship, personAccountData, hasFirmBillingRelation]);

  useEffect(() => {
    if (
      inviteData?.isPaidByFirm &&
      inviteData.status === User.InviteStatus.PENDING &&
      !isModalErrorOpen &&
      !isInviteDataLoading
    ) {
      handleAddToCart();
    }
  }, [inviteData, isInviteDataLoading, isModalErrorOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inviteData?.inviteId) {
      const ifSameMembership = currentMembership?.membership?.membershipTerm?.productId === inviteData?.tier;
      const ifSameEmployer = primaryEmployer?.organization?.id === inviteData?.organizationId;

      if (currentMembership?.membership?.status === Salesforce.MembershipStatus.ACTIVE && ifSameMembership) {
        // If has active membership then the firm send an invite to the user => Received an error
        setMessage(
          `${Firm.FirmBillingErrorMessage.ACTIVE_MEMBERSHIP}. Please contact your Firm Admin as you are unable to progress as you do not qualify for this invite`
        );
        setIsModalErrorOpen(true);
      } else {
        if (
          hasFirmBillingRelation &&
          inviteData?.status === User.InviteStatus.PENDING &&
          primaryEmployer?.id &&
          !ifSameEmployer
        ) {
          // If there's no active membership then the firm send an invite to the user with an existing accepted invite => Received an error
          setMessage(
            `${Firm.FirmBillingErrorMessage.ACTIVE_FIRM_BILLING} Please contact your Firm Admin as you are unable to progress as you do not qualify for this invite`
          );
          setIsModalErrorOpen(true);
        }
      }
    }
  }, [inviteData, hasFirmBillingRelation, currentMembership, primaryEmployer]);

  React.useLayoutEffect(() => {
    const destination = getPath(Routes.MEMBERSHIP_FORM);
    const membershipStatus = MembershipPackageAccordionStatus.Type;

    handleEvent(
      { clickValue: `button:b2c-membership:int:modal-start:${destination}#${membershipStatus}` },
      EVENT_CLICK
    );
  }, []);

  const goToPreviousPage = () => {
    setShowQuoteStatusAndIsMembershipPaidByFirmModal(false);
    dispatch(goToPreviousRoute());
  };

  const handleCloseForQuoteStatusAndIsMembershipPaidByFirmModal = useCallback(() => {
    setShowQuoteStatusAndIsMembershipPaidByFirmModal(false);
  }, []);

  const handleLogoClick = () => {
    history.push(getPath(Routes.ROOT));
  };

  const [applyMembershipModalOpen, setApplyMembershipModalOpen] = useState(false);
  const [isClickedUpgradeMembershipButton, setIsClickedUpgradeMembershipButton] = useState(false);
  const [isClickedApplyMembershipButton, setIsClickedApplyMembershipButton] = useState(false);
  const [upgradeMembershipModalOpen, setUpgradeMembershipModalOpen] = useState(false);
  const [isForUpgradeMembership, setIsForUpgradeMembership] = useState(false);

  const newMember = !isUserMember;
  const hasSelectedMembership = userChoice && Boolean(userChoice.type.id) && Boolean(userChoice.type.slug);
  const hasSelectedCredential = userChoice && Boolean(userChoice.credentials.length);
  const hasSelectedSection =
    Boolean(userChoice.sectionFreeProduct.length) || Boolean(userChoice.sectionProductWithPrice.length);
  const isFirmRosterInvite = Boolean(inviteId) && !Boolean(isPaidByFirm);
  const isFirmBillingInvite = Boolean(inviteId) && Boolean(isPaidByFirm);

  const decimal = useMemo(() => {
    // Flags are converted into bits (1 - true, 0 - false)
    const applicationStateFlag = [
      newMember,
      hasExistingCredential,
      hasExistingSection,
      hasSelectedMembership,
      hasSelectedCredential,
      hasSelectedSection,
      isFirmRosterInvite,
      isFirmBillingInvite,
      isRenewalSeason,
    ]
      .map(val => Number(val))
      .join('');

    // This set of bits are converted into a decimal number
    // e.g: Binary(8) = 1000
    // This means that all flags except hasSelectedSection (the 4th from the last flag) is false
    return parseInt(applicationStateFlag, 2);
  }, [
    newMember,
    hasExistingCredential,
    hasExistingSection,
    hasSelectedMembership,
    hasSelectedCredential,
    hasSelectedSection,
    isFirmRosterInvite,
    isFirmBillingInvite,
    isRenewalSeason,
  ]);
  // if the customerMemberships[0].allTerms has more than one record it means it has done renewal before
  const isUserHasMultipleTerms: boolean = customerMemberships[0]?.allTerms
    ? customerMemberships[0]?.allTerms?.length > 1
    : false;

  const showMembershipTypePanel =
    membershipEvent.isUserLoggedOutClickedApplyMembership && customerMembershipTerm?.expiryDate // if already renewed hide membership type panel
      ? ![8, 9, 16, 17, 145].some((dec: number) => dec === decimal) &&
        moment(customerMembershipTerm?.expiryDate).year() === moment().year()
      : ![8, 9, 16, 17, 145].some((dec: number) => dec === decimal);
  const showMembershipTierPanel =
    membershipEvent.isUserLoggedOutClickedApplyMembership && customerMembershipTerm?.expiryDate // if already renewed hide membership tier panel
      ? ![8, 9, 16, 17, 145].some((dec: number) => dec === decimal) &&
        moment(customerMembershipTerm?.expiryDate).year() === moment().year()
      : ![8, 9, 16, 17, 145].some((dec: number) => dec === decimal);
  const showCredentialPanel =
    customerMembershipTerm?.expiryDate &&
    moment(customerMembershipTerm?.expiryDate).year() >= moment().add(1, 'y').year() &&
    isUserHasMultipleTerms && // if already renewed hide membership tier panel
    !isComingFromPropPage // don't hide if coming credential prop page
      ? ifCredentialEligibleToRenew.length &&
        ![
          4,
          5,
          8,
          9,
          32,
          33, // has selected membership and renewal season turned on
          65,
          258,
          260,
          261,
          264,
          288,
          289,
          292,
          293,
          297,
          ...(!hasSelectedCredential ? [40, 296] : []),
        ].some((dec: number) => dec === decimal)
      : ![
          4,
          5,
          8,
          9,
          32,
          33, // has selected membership and renewal season turned on
          65,
          258,
          260,
          261,
          264,
          288,
          289,
          292,
          293,
          297,
          ...(!hasSelectedCredential ? [40, 296] : []),
        ].some((dec: number) => dec === decimal);

  const showSectionPanel =
    customerMembershipTerm?.expiryDate &&
    isUserHasMultipleTerms &&
    moment(customerMembershipTerm?.expiryDate).year() >= moment().add(1, 'y').year() && // if already renewed hide membership tier panel
    !membershipEvent.isClickedMembershipUpgrade && // don't hide if clicked membership upgrade journey
    !isComingFromPropPage // don't hide if coming section prop page
      ? ifSectionEligibleToRenew.length && ![16, 17, 129, 145, 260].some((dec: number) => dec === decimal)
      : ![16, 17, 129, 145, 260].some((dec: number) => dec === decimal);

  const showAddOnPanel = ![65, 129, 193, 260].some((dec: number) => dec === decimal);

  const handleAddCardEvent = useCallback(
    async (data: any) => {
      if (!data) return;
      const cartItems = await data.lineItems;
      const addToCart = cartItems.map((productItem: any) => {
        const {
          productId,
          name,
          productType,
          quantity,
          variant: { sku = '' } = {},
          totalPrice: { centAmount = 0 } = {},
        } = productItem;

        const amount = Number(centAmount / 100).toFixed(2);
        const isB2BMembership = isB2BMembershipType(productType);
        const isB2CMembership = isB2CMembershipType(productType);

        const payload: CartCompoundAnalytics = {
          id: [productId],
          bundleId: [productId],
          name: [name],
          category: [productType],
          price: [+amount],
          quantity: [quantity],
          sku: [sku],
          orderType: [
            getOrderTypeAnalytics({
              isStandingOrder: false,
              isBundle: false,
              isFreeTrial: !!freeTrialEndDate,
              isB2BMembership,
              isB2CMembership,
            }),
          ],
          shippingCost: [],
          shippingLocation: [],
          shippingType: [],
          productTax: [],
          freeTrialEndDate: [freeTrialEndDate],
        };

        return payload;
      });

      const firstCart = addToCart.slice(0, 1);

      handleEvent(firstCart[0], ADD_CART_FIRST);

      const remainingInCarts = addToCart.splice(1);

      remainingInCarts.map((item: any) => {
        handleEvent(item, ADD_CART);
        return true;
      });
    },
    [freeTrialEndDate]
  );

  const handleAddToCart = useCallback(async () => {
    try {
      const destination = getPath(Routes.MEMBERSHIP_FORM);
      const membershipStatus = MembershipPackageAccordionStatus.Addons;

      await handleEvent(
        { clickValue: `button:b2c-membership:int:modal-complete-next:${destination}#${membershipStatus}` },
        EVENT_CLICK
      );

      dispatch(setHasSelectedAddOns(true));

      const cartLineItems = await dispatch(addMembershipToCart());
      dispatch(updateMembershipApplicationPartTier());
      dispatch(setApplicationInitialLoad(true));
      handleAddCardEvent(cartLineItems.payload.addMembershipToCart);

      let nextRoute;
      let goToCheckout = false;
      switch (decimal) {
        case 258:
        case 260:
        case 264:
        case 272:
        case 288:
          nextRoute = Routes.APPLICATION_FORM;
          break;
        case 16:
        case 17:
        case 49: // newMember: false, hasExistingCredential: false, hasExistingSection: false, hasSelectedMembership: true,  hasSelectedCredential: true, hasSelectedSection: false, isFirmRosterInvite: false, isFirmBillingInvite: false, isRenewalSeason: true,
        case 176:
        case 177:
        case 48:
        case 57: // newMember: false, hasExistingCredential: false, hasExistingSection: false, hasSelectedMembership: true,  hasSelectedCredential: true, hasSelectedSection: true, isFirmRosterInvite: false, isFirmBillingInvite: false, isRenewalSeason: true,
        case 89:
        case 80:
        case 81:
        case 121:
        case 144:
        case 145:
        case 192:
        case 113:
        case 217:
        case 209:
        case 208:
          nextRoute = Routes.CREDENTIALS_APPLICATION_FORM;
          break;
        case 8:
        case 9:
        case 200: // has existing credential, section, and user is member
        case 201: // has existing credential, section, and user is member (Renewal Season)
        case 136: // has existing credential and user is member
        case 137: // has existing credential and user is member (Renewal Season)
        case 72: // has existing section and user is member
        case 73: // has existing section and user is member (Renewal Season)
          goToCheckout = true;
          nextRoute = Routes.CHECKOUT_PAGE;
          break;
        case 65:
        case 129:
        case 249: // newMember = false, hasExisintingCredentials = true, hasExistingSection = true, hasSelectedMembership = true, hasSelectedCredential = true, hasSelectedSection = true, isFirmRosterInvite = false, isFirmBillingInvite = false, isRenewalSeason = true
        case 240: // newMember = false, hasExisintingCredentials = true, hasExistingSection = true, hasSelectedMembership = true, hasSelectedCredential = true, hasSelectedSection = false, isFirmRosterInvite = false, isFirmBillingInvite = false, isRenewalSeason = false
        case 241: // newMember = false, hasExisintingCredentials = true, hasExistingSection = true, hasSelectedMembership = true, hasSelectedCredential = true, hasSelectedSection = false, isFirmRosterInvite = false, isFirmBillingInvite = false, isRenewalSeason = true
          nextRoute = Routes.APPLICATION_FORM_ATTESTATION;
          break;
      }

      if (isRenewalsJourney && hasSelectedCredential) {
        nextRoute = isCimaRenewalSeason
          ? isFCMACredential
            ? Routes.FCMA_CREDENTIAL_APPLICATION_FORM_DONATIONS
            : Routes.APPLICATION_FORM_ATTESTATION
          : Routes.APPLICATION_FORM_ATTESTATION;
      }

      if (goToCheckout) {
        dispatch(setCheckoutPage(Checkout.CheckoutStep.ADDRESS));
      }

      history.push(generatePath(getPath(nextRoute ?? Routes.APPLICATION_FORM)));
    } catch (error) {
      console.error('Error in handleAddToCart:', error);
    }
  }, [
    dispatch,
    history,
    decimal,
    handleAddCardEvent,
    isRenewalsJourney,
    hasSelectedCredential,
    isFCMACredential,
    isCimaRenewalSeason,
  ]);
  const assignPillValue = (screenName: string): string => {
    let pillValueResult: string = '';
    try {
      const { type, tier, sectionFreeProduct, sectionProductWithPrice } = userChoice;
      switch (screenName) {
        case 'MembershipTypePanel':
          pillValueResult = showMembershipTypePanel ? type.slug || eligibleCredential.membershipTypeSlug : '';
          break;

        case 'MembershipTierPanel':
          pillValueResult = showMembershipTierPanel ? tier : '';
          break;
        case 'MembershipSectionPanel':
          pillValueResult = `${sectionFreeProduct.length + sectionProductWithPrice.length}`;
          break;
        default:
          pillValueResult = '';
          break;
      }
      return pillValueResult;
    } catch (e) {
      return '';
    }
  };

  const hasInactiveMembership = () =>
    areAllTruthy(customerInactiveMemberships.length > 0, !customerMemberships?.length);

  const isUserNotAMemberOrHasInactiveMembership = () => hasTruthyValue(!isUserMember, hasInactiveMembership());

  const handleNextClick = useCallback(
    (nextPath: string, accordionKey: string, isRenewal?: boolean) => () => {
      if (isRenewal) {
        handleAddToCart();
        return;
      }

      if (accordionKey === MembershipPackageAccordionStatus.Credentials) {
        dispatch(setHasSelectedCredential(true));
      }

      if (accordionKey === MembershipPackageAccordionStatus.Sections) {
        dispatch(setHasSelectedSection(true));
      }

      history.replace(nextPath);
    },
    [history, dispatch, handleAddToCart]
  );

  // get accordion panel titles
  const accordionTitle = (panelType: MembershipPackageAccordionStatus, isRenewal?: boolean) => {
    let title = '';
    const { Type, Tier, Credentials, Sections, Addons } = MembershipPackageAccordionStatus;

    // credential titles
    const credRenewalOnly = areAllTruthy(hasTruthyValue(isRenewal, isRenewalsJourney), !isMembershipJourney);
    const credNotRenewal = conditionalFunction(useNewSignUpOnly, 'Choose Credentials', 'Choose your credentials');

    // section titles
    const secRenewal = conditionalFunction(isRenewal, 'Renew your sections', 'Choose your sections');

    switch (panelType) {
      case Type:
        title = conditionalFunction(shouldShowNewPackageBuilder, 'Choose Membership Type', 'Choose your type');
        break;
      case Tier:
        title = conditionalFunction(shouldShowNewPackageBuilder, 'Choose Tier', 'Choose your tier');
        break;
      case Credentials:
        title = conditionalFunction(credRenewalOnly, 'Renew your credentials', credNotRenewal);
        break;
      case Sections:
        title = conditionalFunction(shouldShowNewPackageBuilder, 'Choose Sections', secRenewal);
        break;
      case Addons:
        title = conditionalFunction(shouldShowNewPackageBuilder, 'Choose Add-ons', 'Choose your add-ons');
        break;
      default:
        break;
    }
    return title;
  };

  // accordion panels
  const membershipTypePanel = () => ({
    key: MembershipPackageAccordionStatus.Type,
    title: accordionTitle(MembershipPackageAccordionStatus.Type),
    icon: IC_USERS,
    secondaryIcon: IC_USERS_PURPLE,
    content: {
      content: <MembershipTypePanel shouldShowNewPackageBuilder={shouldShowNewPackageBuilder} />,
    },
    pillValue: assignPillValue('MembershipTypePanel') ?? '',
  });

  const membershipTierPanel = (status?: MembershipPackageAccordionStatus, goToApplication?: boolean) => {
    const TierPanel = goToApplication ? (
      <MembershipTierPanel
        goToApplication
        isNotShowingRoleModal={showApplicationModal}
        shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
      />
    ) : (
      <MembershipTierPanel
        status={status}
        isNotShowingRoleModal={showApplicationModal}
        shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
      />
    );

    return {
      key: MembershipPackageAccordionStatus.Tier,
      title: accordionTitle(MembershipPackageAccordionStatus.Tier),
      icon: IC_AWARD,
      secondaryIcon: IC_AWARD_PURPLE,
      content: {
        content: TierPanel,
      },
      pillValue: assignPillValue('MembershipTierPanel') ?? '',
    };
  };

  const credentialPanel = (nextPath?: any, isRenewal?: boolean) => {
    const hasSelected = areAllTruthy(useNewSignUpOnly, !!userChoiceMembershipCredential?.length);
    return {
      key: MembershipPackageAccordionStatus.Credentials,
      title: accordionTitle(MembershipPackageAccordionStatus.Credentials, isRenewal),
      icon: IC_AWARD,
      secondaryIcon: IC_AWARD_PURPLE,
      content: {
        content: (
          <MembershipCredentialPanel
            setHasErrorInCredentials={setHasErrorInCredentials}
            setErrorCredentialProps={setErrorCredentialProps}
            handleClick={handleNextClick(nextPath, MembershipPackageAccordionStatus.Credentials, isRenewal)}
            decimal={decimal}
            upgradeMembershipModalOpen={upgradeMembershipModalOpen}
            applyMembershipModalOpen={applyMembershipModalOpen}
            hasSelected={hasSelected}
            useNewMembershipAICPA={useNewSignUpOnly}
          />
        ),
      },
    };
  };

  const sectionPanel = (nextPath?: any, isRenewal?: boolean) => ({
    key: MembershipPackageAccordionStatus.Sections,
    title: accordionTitle(MembershipPackageAccordionStatus.Sections, isRenewal),
    icon: IC_LAYERS,
    secondaryIcon: IC_LAYERS_PURPLE,
    content: {
      content: (
        <MembershipSectionPanel
          handleClick={handleNextClick(nextPath, MembershipPackageAccordionStatus.Sections, isRenewal)}
          shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
        />
      ),
    },
    pillValue: assignPillValue('MembershipSectionPanel'),
  });

  const addOnPanel = () => ({
    key: MembershipPackageAccordionStatus.Addons,
    title: accordionTitle(MembershipPackageAccordionStatus.Addons),
    icon: IC_PACKAGE,
    secondaryIcon: IC_PACKAGE_PURPLE,
    content: {
      content: (
        <>
          {shouldShowNewPackageBuilder && (
            <StyledMembershipInfo>
              Select the desired add-ons for your membership
              <StyledInfoBubble />
            </StyledMembershipInfo>
          )}
          <MembershipAddonPanel
            handleClick={handleAddToCart}
            shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}
          />
        </>
      ),
    },
  });

  // show membership type panel in accordion
  const showMembershipTypeInAccordion = (accordion: MembershipPackageAccordionItemProps[]) => {
    let typeAcc = accordion;
    if (
      areAllTruthy(
        showMembershipTypePanel,
        !sectionsCredentialsRenewal.isTriggered,
        !membershipEvent.isClickedSectionsJourney,
        !isCredentialsJourney
      )
    ) {
      typeAcc = [...typeAcc, membershipTypePanel()];
    }

    if (
      hasTruthyValue(
        isForUpgradeMembership,
        areAllTruthy(
          hasTruthyValue(membershipEvent.isClickedSectionsJourney, isCredentialsJourney),
          isUserNotAMemberOrHasInactiveMembership()
        )
      )
    ) {
      typeAcc = [...typeAcc, membershipTypePanel()];
    }
    return typeAcc;
  };

  // show membership tier panel in accordion
  const showMembershipTierInAccordion = (accordion: MembershipPackageAccordionItemProps[]) => {
    let tierAcc = accordion;
    if (
      areAllTruthy(
        showMembershipTierPanel,
        !sectionsCredentialsRenewal.isTriggered,
        !membershipEvent.isClickedSectionsJourney,
        !isCredentialsJourney
      )
    ) {
      const getTierNextStatus = () => {
        if (!isRenewalsJourney) {
          return conditionalFunction(
            hasTruthyValue(showCredentialPanel, isCredentialsJourney, shouldShowNewPackageBuilder),
            MembershipPackageAccordionStatus.Credentials,
            MembershipPackageAccordionStatus.Sections
          );
        }
        return conditionalFunction(
          activeCredentials.length,
          MembershipPackageAccordionStatus.Credentials,
          MembershipPackageAccordionStatus.Sections
        );
      };
      tierAcc = [...tierAcc, membershipTierPanel(getTierNextStatus(), decimal === 260)];
    }

    if (
      hasTruthyValue(
        isForUpgradeMembership,
        areAllTruthy(
          hasTruthyValue(membershipEvent.isClickedSectionsJourney, isCredentialsJourney),
          hasTruthyValue(!isUserMember, customerInactiveMemberships.length > 0)
        )
      )
    ) {
      const getTierNextStatus = () => {
        return conditionalFunction(
          hasTruthyValue(showCredentialPanel, isCredentialsJourney, shouldShowNewPackageBuilder),
          MembershipPackageAccordionStatus.Credentials,
          MembershipPackageAccordionStatus.Sections
        );
      };
      tierAcc = [...tierAcc, membershipTierPanel(getTierNextStatus(), decimal === 260)];
    }
    return tierAcc;
  };

  // show credentials and sections in accordion
  const getCredentialNextStatus = () => {
    if (areAllTruthy(isCredentialsJourney, isUserMember, !isForUpgradeMembership)) {
      return getMembershipPackagePath(MembershipPackageAccordionStatus.Addons);
    }
    if (
      areAllTruthy(
        sectionsCredentialsRenewal.isTriggered,
        sectionsCredentialsRenewal.productType === Product.ProductType.CREDENTIAL,
        showSectionPanel
      )
    ) {
      return getMembershipPackagePath(MembershipPackageAccordionStatus.Sections);
    }
    if (
      areAllTruthy(
        sectionsCredentialsRenewal.isTriggered,
        sectionsCredentialsRenewal.productType === Product.ProductType.SECTION,
        showSectionPanel
      )
    ) {
      return getMembershipPackagePath(MembershipPackageAccordionStatus.Addons);
    }
    if (showSectionPanel) return getMembershipPackagePath(MembershipPackageAccordionStatus.Sections);

    return getMembershipPackagePath(MembershipPackageAccordionStatus.Addons);
  };

  // If Renew credentials is triggered - set next to addons
  // if Renew section is triggered - set next to credentials
  const getSectionNextStatus = () => {
    if (
      areAllTruthy(
        sectionsCredentialsRenewal.isTriggered,
        sectionsCredentialsRenewal.productType === Product.ProductType.SECTION
      )
    ) {
      if (activeCredentials.length) return getMembershipPackagePath(MembershipPackageAccordionStatus.Credentials);
      return getMembershipPackagePath(MembershipPackageAccordionStatus.Addons);
    }
    if (
      hasTruthyValue(
        showAddOnPanel,
        areAllTruthy(
          moment(customerMembershipTerm?.expiryDate).year() >= moment().add(1, 'y').year(),
          isUserHasMultipleTerms
        )
      )
    ) {
      return getMembershipPackagePath(MembershipPackageAccordionStatus.Addons);
    }
    return null;
  };

  // hide section accordion when there are no sections for renewal
  const hideSecPanelInCredRenewal = (accordion: MembershipPackageAccordionItemProps[]) => {
    let acc = accordion;
    const isSectionRenewed = sectionsActive.filter(sections => moment(sections.endDate).year() > moment().year());
    if (showSectionPanel && !isSectionRenewed.length) {
      acc = [...acc, sectionPanel(getSectionNextStatus(), decimal === 65)];
    }
    return acc;
  };

  const isNonRenewalOrActiveCred = (accordion: MembershipPackageAccordionItemProps[]) => {
    let acc = accordion;
    if (hasTruthyValue(activeCredentials.length, !isRenewalsJourney, shouldShowNewPackageBuilder)) {
      acc = [
        ...acc,
        credentialPanel(
          getCredentialNextStatus(),
          [129].some(dec => dec === decimal)
        ),
      ];
    }
    return acc;
  };

  const showCredSecPanelsInAccordion = (accordion: MembershipPackageAccordionItemProps[]) => {
    let credSecAcc = accordion;
    if (
      hasTruthyValue(
        shouldShowNewPackageBuilder,
        areAllTruthy(
          sectionsCredentialsRenewal.isTriggered,
          sectionsCredentialsRenewal.productType === Product.ProductType.CREDENTIAL,
          showCredentialPanel
        )
      )
    ) {
      credSecAcc = [
        ...credSecAcc,
        credentialPanel(
          getCredentialNextStatus(),
          [129].some(dec => dec === decimal)
        ),
      ];

      credSecAcc = hideSecPanelInCredRenewal(credSecAcc);
    } else if (
      areAllTruthy(
        sectionsCredentialsRenewal.isTriggered,
        sectionsCredentialsRenewal.productType === Product.ProductType.SECTION,
        showSectionPanel
      )
    ) {
      // If Renew section is triggered, section accordion must show first before credentials
      if (!isCredentialsJourney) credSecAcc = [...credSecAcc, sectionPanel(getSectionNextStatus(), decimal === 65)];
      if (activeCredentials.length) {
        credSecAcc = [
          ...credSecAcc,
          credentialPanel(
            getCredentialNextStatus(),
            [129].some(dec => dec === decimal)
          ),
        ];
      }
    } else {
      if (
        areAllTruthy(
          !membershipEvent.isClickedSectionsJourney,
          hasTruthyValue(showCredentialPanel, isCredentialsJourney)
        )
      ) {
        credSecAcc = isNonRenewalOrActiveCred(credSecAcc);
      }
      if (
        areAllTruthy(hasTruthyValue(showSectionPanel, membershipEvent.isClickedSectionsJourney), !isCredentialsJourney)
      ) {
        credSecAcc = [...credSecAcc, sectionPanel(getSectionNextStatus(), decimal === 65)];
      }
      if (hasTruthyValue(isForUpgradeMembership, areAllTruthy(isCredentialsJourney, !isUserMember))) {
        credSecAcc = [...credSecAcc, sectionPanel(getSectionNextStatus(), decimal === 65)];
      }
    }

    return credSecAcc;
  };

  const memoizedAccordion = useMemo(() => {
    let accordion: MembershipPackageAccordionItemProps[] = [];

    accordion = showMembershipTypeInAccordion(accordion);
    accordion = showMembershipTierInAccordion(accordion);

    // If Renew credentials is triggered - set next to sections
    // if Renew section is triggered - set next to addons
    if (
      areAllTruthy(
        hasTruthyValue(
          membershipEvent.isClickedMembershipUpgrade,
          membershipEvent.isUserLoggedOutClickedApplyMembership
        ),
        moment(customerMembershipTerm?.expiryDate).year() >= moment().add(1, 'y').year(),
        isUserHasMultipleTerms,
        !membershipEvent.isClickedMembershipRenewal
      )
    ) {
      if (activeCredentials.length) {
        getMembershipPackagePath(MembershipPackageAccordionStatus.Credentials);
      }
      if (activeSections.length) {
        getMembershipPackagePath(MembershipPackageAccordionStatus.Sections);
      }
    }

    accordion = showCredSecPanelsInAccordion(accordion);

    // show add-ons panel in accordion
    if (
      hasTruthyValue(
        showAddOnPanel,
        isRenewalsJourney,
        membershipEvent.isClickedMembershipUpgrade,
        membershipEvent.isUserLoggedOutClickedApplyMembership
      )
    ) {
      accordion = [...accordion, addOnPanel()];
    }

    return accordion;
    // NOTE: added eslint disable since when adding customerMembershipTerm makes the app loop inside use effect.
    // eslint-disable-next-line
  }, [
    isForUpgradeMembership,
    customerMemberships,
    isUserMember,
    membershipEvent,
    isRenewalsJourney,
    activeCredentials,
    applyMembershipModalOpen,
    showMembershipTypePanel,
    showMembershipTierPanel,
    showCredentialPanel,
    showSectionPanel,
    showAddOnPanel,
    decimal,
    sectionsCredentialsRenewal,
    upgradeMembershipModalOpen,
    isCredentialsJourney,
    showApplicationModal,
    customerInactiveMemberships,
    handleAddToCart,
    handleNextClick,
    activeSections,
    activeCredentials,
    userChoice,
  ]);

  const [accordionData, setData] = useState(memoizedAccordion);

  const onBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => setData(memoizedAccordion), [decimal, memoizedAccordion]);

  useEffect(() => {
    setData(currData => {
      // TODO: Update redux to also include actual type/tier selected. Right now it will show only the type slug and tier SKU
      const { type, tier, credentials, sectionFreeProduct, sectionProductWithPrice, addons } = userChoice;

      const pillValue: string[] = [
        showMembershipTypePanel ? type.slug || eligibleCredential.membershipTypeSlug : '',
        showMembershipTierPanel ? tier : '',
        showCredentialPanel ? `${credentials.length}` : '',
        showSectionPanel ? `${sectionFreeProduct.length + sectionProductWithPrice.length}` : '0',
        showAddOnPanel ? `${addons.length}` : '0',
      ].filter(val => val);

      return currData.map((panelData, i) => ({
        ...panelData,
        pillValue: pillValue[i],
      }));
    });
  }, [
    userChoice,
    eligibleCredential.membershipTypeSlug,
    showMembershipTypePanel,
    showMembershipTierPanel,
    showCredentialPanel,
    showSectionPanel,
    showAddOnPanel,
  ]);

  const shouldDisplayApplicationProgressModal =
    !isUserMember || (isAicpaMember && !hasExistingCredential && hasCredentialInCart);

  useEffect(() => {
    if (application.applicationProgress && shouldDisplayApplicationProgressModal) {
      const lastSavedPath = getPath(ApplicationProgressRoutesMap[application.applicationProgress]);

      if (lastSavedPath !== location.pathname) {
        setShowApplicationModal(true);
      }
    }
  }, [
    customerProfileFetched,
    shouldDisplayApplicationProgressModal,
    location.pathname,
    application.applicationProgress,
  ]);

  useEffect(() => {
    if (!location?.hash?.replace('#', '')) {
      if (
        (membershipEvent.isClickedMembershipUpgrade || membershipEvent.isUserLoggedOutClickedApplyMembership) &&
        moment(customerMembershipTerm?.expiryDate).year() >= moment().add(1, 'y').year() &&
        isUserHasMultipleTerms &&
        !membershipEvent.isClickedMembershipRenewal
      ) {
        if (ifCredentialEligibleToRenew.length) {
          history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Credentials));
        } else if (ifSectionEligibleToRenew.length) {
          history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Sections));
        } else {
          history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Type));
        }
      } else {
        history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Type));
      }
    } else {
      const currPage = location.hash.replace('#', '');

      if (
        currPage === MembershipPackageAccordionStatus.Sections &&
        eligibleSection?.membershipTypeSlug &&
        eligibleSection?.tier === 'not eligible' &&
        !eligibleSection?.eligible
      ) {
        setApplyMembershipModalOpen(true);
      }

      if (currPage !== MembershipPackageAccordionStatus.Type && !userChoice.type) {
        history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Type));
      } else if (
        currPage === MembershipPackageAccordionStatus.Credentials &&
        eligibleCredential?.tier === 'not eligible' &&
        !eligibleCredential.eligible
      ) {
        // if not yet clicked the upgrade membership, display the modal
        if (!isClickedUpgradeMembershipButton) {
          setIsForUpgradeMembership(true);
          setUpgradeMembershipModalOpen(true);
        }
      } else if (
        [
          MembershipPackageAccordionStatus.Sections,
          MembershipPackageAccordionStatus.Credentials,
          MembershipPackageAccordionStatus.Addons,
        ].indexOf(currPage as MembershipPackageAccordionStatus) >= 0 &&
        !userChoice.tier &&
        !sectionsCredentialsRenewal.isTriggered &&
        !membershipEvent.isClickedSectionsJourney
      ) {
        if (membershipEvent.isClickedCredentialsSectionsRenewal) {
          dispatch(setMembershipEvent('isClickedCredentialsSectionsRenewal', false));
        } else {
          if (
            [MembershipTypes.MembershipState.TERMINATED, MembershipTypes.MembershipState.RESIGNED].includes(
              currentMembership?.membership?.status as MembershipTypes.MembershipState
            ) &&
            !membershipEvent.isClickedMembershipBuyAgain
          ) {
            setApplyMembershipModalOpen(true);
          }
        }
        if (!isLocationChange) {
          if (
            moment(customerMembershipTerm?.expiryDate).year() >= moment().add(1, 'y').year() &&
            isUserHasMultipleTerms
          ) {
            if (ifCredentialEligibleToRenew.length) {
              history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Credentials));
            } else if (ifSectionEligibleToRenew.length) {
              history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Sections));
            }
            setLocationChange(true);
          } else {
            history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Type));
            setLocationChange(true);
          }
        }
      }
    }
  }, [
    dispatch,
    history,
    location,
    userChoice,
    eligibleCredential,
    eligibleSection,
    isClickedUpgradeMembershipButton,
    sectionsCredentialsRenewal,
    membershipEvent,
    currentMembership,
    isClickedApplyMembershipButton,
    customerMembershipTerm,
    isLocationChange,
    ifCredentialEligibleToRenew,
    ifSectionEligibleToRenew,
    isUserHasMultipleTerms,
  ]);

  useEffect(() => {
    const currPage = location.hash.replace('#', '');
    if (
      currPage !== MembershipPackageAccordionStatus.Tier &&
      membershipEvent.isUserLoggedOutClickedApplyMembership &&
      userChoice.type.id
    ) {
      if (moment(customerMembershipTerm?.expiryDate).year() >= moment().add(1, 'y').year() && isUserHasMultipleTerms) {
        if (ifCredentialEligibleToRenew.length) {
          history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Credentials));
        } else if (ifSectionEligibleToRenew.length) {
          history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Sections));
        } else {
          history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Type));
        }
        dispatch(setMembershipEvent('isClickedMembershipUpgrade', true));
      } else {
        history.replace(getMembershipPackagePath(MembershipPackageAccordionStatus.Type));
      }
      dispatch(setMembershipEvent('isUserLoggedOutClickedApplyMembership', false));
    }
  }, [
    membershipEvent,
    userChoice,
    history,
    location,
    dispatch,
    ifCredentialEligibleToRenew,
    ifSectionEligibleToRenew,
    customerMembershipTerm,
    isUserHasMultipleTerms,
  ]);

  useEffect(() => {
    if (
      !isClickedApplyMembershipButton &&
      !(
        [
          Salesforce.MembershipStatus.ACTIVE,
          Salesforce.MembershipStatus.GRACE,
          Salesforce.MembershipStatus.SUSPENDED,
        ] as string[]
      ).includes(currentMembership?.membership?.status || '') &&
      !membershipEvent.isClickedMembershipBuyAgain &&
      !isUserMemberLapsed &&
      customerInactiveMemberships.length
    ) {
      setApplyMembershipModalOpen(true);
    }
  }, [
    currentMembership,
    isClickedApplyMembershipButton,
    membershipEvent,
    customerInactiveMemberships,
    isUserMemberLapsed,
  ]);

  useEffect(() => {
    if (
      newMember &&
      (membershipPropPageURL?.variant || membershipPropPageURL?.credential || membershipPropPageURL?.section)
    ) {
      setApplyMembershipModalOpen(true);
    }
  }, [membershipPropPageURL, newMember]);

  useEffect(() => {
    if (cart?.lineItems?.find(({ productType }) => productType === 'membership')) {
      history.replace(getPath(Routes.APPLICATION_FORM));
    }
  }, [history, cart]);

  useEffect(() => {
    const index = accordionData.findIndex(({ key }) => key === location.hash.replace('#', ''));

    if (index !== activeIndex) {
      setActiveIndex(index < 0 ? 0 : index);
    }

    if (index > maxIndex) {
      setMaxIndex(index < 0 ? 0 : index);
    }
  }, [location, accordionData, maxIndex, activeIndex]);

  const handleAccordionClick = useCallback(
    (e, { index }: AccordionTitleProps) => {
      if (activeIndex === 0) return;
      if (index !== undefined && index <= maxIndex) {
        // This Condition is for the Upward Arrow Accordion, the function will reset the next and previous selection. And move the selection head step back one
        if (index === activeIndex && index !== 0) {
          const stepBackOne = index - 1;
          dispatch(resetUserChoiceSlug(`${accordionData[stepBackOne].key as MembershipPackageAccordionStatus}`));
          dispatch(resetUserChoiceSlug(`${accordionData[index].key as MembershipPackageAccordionStatus}`));
          history.replace(
            `${getMembershipPackagePath(accordionData[stepBackOne].key as MembershipPackageAccordionStatus)}`
          );
        } else {
          // This Condition is for the Downward Arrow Accordion and the SlugPill, the function will reset the current selection and the next one.
          setActiveIndex(+index);
          if (index > maxIndex) {
            setMaxIndex(+index);
          }
          if (typeof index === 'number') {
            const stepAddOne = index + 1;
            dispatch(resetUserChoiceSlug(`${accordionData[stepAddOne].key as MembershipPackageAccordionStatus}`));
            if (useNewSignUpOnly && stepAddOne !== 3) {
              dispatch(resetUserChoiceSlug(`${accordionData[+index].key as MembershipPackageAccordionStatus}`));
            } else if (!useNewSignUpOnly) {
              dispatch(resetUserChoiceSlug(`${accordionData[+index].key as MembershipPackageAccordionStatus}`));
            }
          }
          history.replace(`${getMembershipPackagePath(accordionData[+index].key as MembershipPackageAccordionStatus)}`);
        }
      }
    },
    [maxIndex, history, accordionData, dispatch, activeIndex, useNewSignUpOnly]
  );

  const onClickConfirmButton = useCallback(() => {
    setIsClickedUpgradeMembershipButton(true);
  }, [setIsClickedUpgradeMembershipButton]);

  const onClickConfirmButtonApply = useCallback(() => {
    setIsClickedApplyMembershipButton(true);
  }, [setIsClickedApplyMembershipButton]);

  const ifInCredentialsOrSections = useMemo(() => {
    const currPage = location.hash.replace('#', '');
    if (currPage !== MembershipPackageAccordionStatus.Type && currPage !== MembershipPackageAccordionStatus.Tier) {
      return true;
    }
    return false;
  }, [location.hash]);

  const customerMembershipTermIsPaidByFirm = useMemo(
    () => (Object.keys(customerMembershipTerm).length === 0 ? false : JSON.parse(customerMembershipTerm?.isPaidByFirm)),
    [customerMembershipTerm]
  );

  const isShowingFirmModal = useMemo(() => {
    if (membershipEvent.isClickedMembershipRenewal && !customerMembershipTermIsPaidByFirm) return false;
    return true;
  }, [membershipEvent, customerMembershipTermIsPaidByFirm]);

  const goToFeed = useCallback(() => {
    history.push(getPath(Routes.FEED));
  }, [history]);

  if (isModalErrorOpen) {
    return <ModalFirmBillingFLPRequirementsNotMet isOpen={isModalErrorOpen} handleClose={goToFeed} message={message} />;
  }

  if (inviteData.status === 'Removed' || isNoInviteData) {
    return <CimaNotInvitedMember bodyText={firmBillingErrorMessage} />;
  }

  if (isMembershipTypesLoading || !customerProfileFetched || addCartLoading) {
    return <Loader active content="Loading" />;
  }

  const isCGMAAndHasSuspendedEthicsStatus =
    ethicsStatusSelector === MembershipTypes.EthicsStatusEnum.SUSPENDED &&
    userChoiceMembershipCredential.some(data => data.credentialKey === MembershipTypes.CredentialKeys.CGMA);

  const isUpgradeAndHasSuspendedEthicsStatus =
    ethicsStatusSelector === MembershipTypes.EthicsStatusEnum.SUSPENDED && membershipEvent.isClickedMembershipUpgrade;

  const currentJourney = () => {
    return isCredentialsJourney
      ? CurrentJourney.CREDENTIALS
      : membershipEvent.isClickedSectionsJourney
      ? CurrentJourney.SECTIONS
      : undefined;
  };

  if (userHasEthicsViolation || isCGMAAndHasSuspendedEthicsStatus || isUpgradeAndHasSuspendedEthicsStatus) {
    // for new user's that has an ethics violation
    return (
      <ModalEthicsAndFirmBillingInvite
        ethicsStatusSelector={ethicsStatusSelector}
        hasFirmBillingInvite={hasFirmBillingInvite}
        currentJourney={currentJourney()}
      />
    );
  }

  if (isNotAllowedForMembershipTypes) {
    return (
      <ModalError
        headerText="You cannot proceed with this Membership Type."
        bodyText="Please refresh to select the allowed membership types"
        isOpen={true}
        handleClose={handleCloseModal}
        isNotAllowedToClose={true}
      />
    );
  }

  // NOTE: this change won't allow renewal for memberships that is already renewed.
  if (
    !sectionsCredentialsRenewal.isTriggered &&
    ifSectionEligibleToRenew.length === 0 &&
    ifCredentialEligibleToRenew.length === 0 &&
    customerMembershipTerm?.expiryDate &&
    moment(customerMembershipTerm?.expiryDate).year() >= moment().add(1, 'y').year() &&
    isUserHasMultipleTerms &&
    !membershipEvent.isClickedSectionsJourney &&
    !membershipEvent.isClickedCredentialsSectionsRenewal &&
    !isCredentialsJourney &&
    !isComingFromPropPage &&
    !membershipEvent.isClickedMembershipUpgrade &&
    !isFLPSwitch &&
    !isFLPUpgrade
  ) {
    const renewText =
      'Please refer to the "My membership and benefits" section of your profile for your current membership benefits and expiration information.';
    return (
      <ModalError
        headerText="Your membership has already been renewed for this year."
        bodyText={renewText}
        isOpen={true}
        handleClose={goToPreviousPage}
        isNotAllowedToClose={false}
      />
    );
  }

  return (
    <StyledBackground>
      <Helmet>
        <title>Membership Package Builder | AICPA & CIMA</title>
      </Helmet>
      {hasErrorInCredentials && (
        <ModalError
          {...errorCredentialProps}
          isOpen={hasErrorInCredentials}
          handleClose={handleCloseCredentialsErrorModal}
        />
      )}
      {!!(!userHasEthicsViolation && upgradeMembershipModalOpen) && (
        <ModalUpgradeMembership
          visible={upgradeMembershipModalOpen}
          setVisibility={setUpgradeMembershipModalOpen}
          onClickConfirmButton={onClickConfirmButton}
        />
      )}
      {applyMembershipModalOpen && (
        <ModalApplyMembership
          visible={applyMembershipModalOpen}
          setVisibility={setApplyMembershipModalOpen}
          isUserMember={isUserMember}
          isSection={location.hash.replace('#', '') === MembershipPackageAccordionStatus.Sections}
          onClickConfirmButton={onClickConfirmButtonApply}
        />
      )}
      {showApplicationModal && (
        <ModalApplicationRedirect
          showApplicationModal={showApplicationModal}
          setShowApplicationModal={setShowApplicationModal}
          applicationProgress={getPath(ApplicationProgressRoutesMap[application.applicationProgress || ''])}
          dispatch={dispatch}
        />
      )}
      {showQuoteStatusAndIsMembershipPaidByFirmModal &&
        isUserMember &&
        !ifInCredentialsOrSections &&
        isShowingFirmModal && (
          <ModalUpgradingMembership
            isOpen={showQuoteStatusAndIsMembershipPaidByFirmModal}
            handleClose={goToPreviousPage}
            handleSubmit={handleCloseForQuoteStatusAndIsMembershipPaidByFirmModal}
            quoteStatusAndIsMembershipPaidByFirm={{
              isPaidByFirm: showQuoteStatusAndIsMembershipPaidByFirmModal,
              quoteStatusIsNotRejected: customerMembershipTermIsPaidByFirm,
            }}
            useNewMembershipAICPA={useNewMembershipAICPA}
          />
        )}

      {
        // if journey is not from firm billing invite
        !inviteData?.inviteId &&
        // and if has firm billing invite but not yet accepted | or if has firm billing invite, already accepted but the quote/invoice is not yet generated
        ((hasFirmBillingInvite && !showQuoteStatusAndIsMembershipPaidByFirmModal) ||
          (hasFirmBillingInvite &&
            showQuoteStatusAndIsMembershipPaidByFirmModal &&
            !customerMembershipTermIsPaidByFirm)) &&
        // and if on initial purchase
        !ifInCredentialsOrSections &&
        !membershipEvent.isClickedMembershipRenewal &&
        !membershipEvent.isClickedMembershipUpgrade ? (
          <ModalEthicsAndFirmBillingInvite
            ethicsStatusSelector={ethicsStatusSelector}
            hasFirmBillingInvite={hasFirmBillingInvite}
            currentJourney={currentJourney()}
          />
        ) : (
          <>
            {useNewSignUpOnly ? (
              <React.Fragment>
                <PageMembershipHeader />
              </React.Fragment>
            ) : (
              <>
                <OnlyDesktop minWidth={768}>
                  <StyledHeaderWrapper>
                    <StyledHeaderWrapperContainer>
                      <StyledAicpaCimaLogoWhite onClick={handleLogoClick} />
                      <StyledMiniUserWidget showLogoutLink isWhite={true} />
                    </StyledHeaderWrapperContainer>
                  </StyledHeaderWrapper>
                </OnlyDesktop>
                <OnlyMobile maxWidth={767}>
                  <StyledHeaderWrapper>
                    <StyledHeaderWrapperContainer>
                      <StyledAicpaCimaLogoWhiteMobile onClick={handleLogoClick} />
                      <StyledMiniUserWidget showLogoutLink isWhite={true} />
                    </StyledHeaderWrapperContainer>
                  </StyledHeaderWrapper>
                </OnlyMobile>
                <StyledBackLinkContainer>
                  <StyledBackLink onClick={onBackClick}>
                    {/* tslint:disable-line */}
                    <StyledBackIcon src={IC_ACCORDION_LEFT} /> Back
                  </StyledBackLink>
                </StyledBackLinkContainer>
              </>
            )}

            <StyledHeaderContainer>
              <StyledHeading as="h1">
                {sectionsCredentialsRenewal.isTriggered ||
                ((membershipEvent.isClickedMembershipUpgrade ||
                  membershipEvent.isUserLoggedOutClickedApplyMembership) &&
                  moment(customerMembershipTerm?.expiryDate).year() >= moment().add(1, 'y').year() &&
                  isUserHasMultipleTerms &&
                  ifSectionEligibleToRenew.length > 0 &&
                  ifCredentialEligibleToRenew.length > 0) ? (
                  <>
                    {/* TODO: Create a constant in contentful for this header */}
                    {/* Renew your <StyledTextBold>Credentials and Sections</StyledTextBold> */}
                  </>
                ) : (
                  <>{/* {pageHeaderPart1} <StyledTextBold>{pageHeaderPart2}</StyledTextBold> */}</>
                )}
              </StyledHeading>
              <StyledSubHeading>{pageSubHeader}</StyledSubHeading>
              <StyledContainer shouldShowNewPackageBuilder={shouldShowNewPackageBuilder}>
                <MembershipPackageAccordion
                  data={accordionData}
                  activeIndex={activeIndex}
                  maxIndex={maxIndex}
                  onClick={handleAccordionClick}
                  onClickCancelCTA={goToFeed}
                  onClickProceedCTA={handleAddToCart}
                  currentAccordion={currentAccordion}
                />
              </StyledContainer>
            </StyledHeaderContainer>
          </>
        )
      }
    </StyledBackground>
  );
};

const StyledIcon = styled(Icon)<{ isCursorPointer?: boolean; isFloatRight?: boolean }>`
  &&&& {
    color: ${props => props.theme.colors.primaryDarkPurple};
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    font-size: ${props => props.theme.fontSizes.m};
    margin-right: ${props => props.theme.pxToRem(10)};
    cursor: ${props => (props.isCursorPointer ? 'pointer' : 'default')};
    ${props => (props.isFloatRight ? ' float: right' : '')};
  }
`;

const StyledPopup = styled(Popup)`
  &&&& {
    padding: ${props => props.theme.pxToRem(30)} ${props => props.theme.pxToRem(24)} ${props => props.theme.pxToRem(20)};
    min-width: ${props => props.theme.pxToRem(100)};
  }
`;

const StyledHeaderContainer = styled(Container)`
  /* transform: translateY(-6rem); */
  &&&&& {
    width: ${props => props.theme.pxToRem(1280)} !important;
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: 0 !important;
      left: 0;
      text-align: center;
  }
`;

const StyledMiniUserWidget = styled(MiniUserWidgetContainer)`
  color: ${props => props.theme.colors.neutralWhite} !important;
  text-decoration: none;
`;

const StyledAicpaCimaLogoWhite = styled(AicpaCimaLogoWhite)`
  cursor: pointer;
`;

const StyledAicpaCimaLogoWhiteMobile = styled(AicpaCimaLogoWhiteMobile)`
  cursor: pointer;
  float: left !important;
  height: ${props => props.theme.pxToRem(34)};
  width: ${props => props.theme.pxToRem(48)};
`;

const StyledHeaderWrapper = styled.div`
  &&&& {
    background-image: linear-gradient(73deg, #6a2d87 35%, #a93d69 101%);
    padding: ${props => props.theme.pxToRem(16)} ${props => props.theme.pxToRem(16)};
  }
`;

const StyledHeaderWrapperContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.theme.mediaQueries.computerMin} {
    width: calc(100% - 20rem);
  }
`;

const StyledGrid = styled(Grid)`
  &&.ui.grid {
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
`;

const StyledTypeContainer = styled.div`
  ${props => props.theme.mediaQueries.mobileOnly} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const StyledBackground = styled.div`
  background-color: ${props => props.theme.colors.neutralWhite};
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  ${props => props.theme.mediaQueries.mobileOnly} {
    overflow-x: visible;
  }
`;

const StyledHeading = styled(Heading)`
  text-align: center !important;
  color: ${props => props.theme.colors.primaryPurple};
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.xxl};
  font-family: ${props => props.theme.fontFamily};
  margin-top: ${props => props.theme.pxToRem(80)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.fontSizes.xl};
  }
`;

const StyledBackLinkContainer = styled.div`
  background-color: ${props => props.theme.colors.neutralGrey1};
`;
const StyledBackLink = styled.div`
  display: flex;
  font-size: ${props => props.theme.fontSizes.xxs}
  font-weight: ${props => props.theme.fontWeights.regular};
  text-decoration: none;
  font-family:'Roboto';
  color: ${props => props.theme.colors.primaryPurple} !important;
  padding: ${props => props.theme.pxToRem(12)};
  width: 100%;
  margin: 0 auto;
  > svg {
    width: ${props => props.theme.pxToRem(18)};
    height: ${props => props.theme.pxToRem(18)};
    margin-right: ${props => props.theme.pxToRem(4)};
    path {
      fill: ${props => props.theme.colors.primaryPurple};
    }
  }
  ${props => props.theme.mediaQueries.computerMin} {
    width: calc(100% - 20rem);
  }
  cursor: pointer;
`;

const StyledBackIcon = styled.img`
  cursor: pointer;
  height: ${props => props.theme.pxToRem(18)};
  width: ${props => props.theme.pxToRem(18)};
`;

const StyledSubHeading = styled.div`
  color: ${({ theme }) => theme.colors.neutralWhite} !important;
  font-weight: ${props => props.theme.fontWeights.light} !important;
  text-align: center;
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.theme.fontSizes.s};

  ${props => props.theme.mediaQueries.mobileOnly} {
    padding-bottom: ${props => props.theme.pxToRem(48)};
    font-size: ${props => props.theme.fontSizes.xs};
  }
  ${props => props.theme.mediaQueries.desktopOnly} {
    padding-bottom: ${props => props.theme.pxToRem(30)};
  }
`;

interface StyledLiProps {
  changeColor: boolean;
  isItemDisabled: boolean;
}

const getVerticalLineMobile = (props: any) => {
  return props.activeIndex === 1 && props.index === 2 && !props.isCenterMembershipJourney
    ? '50%'
    : props.activeIndex === 2 && props.index === 3 && props.isCenterMembershipJourney
    ? '78%'
    : props.activeIndex === 3 && props.index === 4 && !props.isCenterMembershipJourney
    ? '127%'
    : props.activeIndex === 4 && props.index === 5 && !props.isCenterMembershipJourney
    ? '167%'
    : '';
};
const getVerticalLineTablet = (props: any) => {
  return props.activeIndex === 2 && props.index === 3 && props.isCenterMembershipJourney
    ? '82%'
    : props.activeIndex === 3 && props.index === 4 && !props.isCenterMembershipJourney
    ? '127%'
    : '';
};

const getVerticalLineDesktop = (props: any) => {
  return props.activeIndex === 1 && props.index === 2 && !props.isCenterMembershipJourney
    ? '40%'
    : props.activeIndex === 2 && props.index === 3 && props.isCenterMembershipJourney
    ? '61%'
    : props.activeIndex === 3 && props.index === 4 && !props.isCenterMembershipJourney
    ? '111%'
    : props.activeIndex === 4 && props.index === 5 && !props.isCenterMembershipJourney
    ? '140%'
    : '';
};

const StyledUl = styled.ul<{
  index: number;
  activeIndex: number;
  isCenterMembershipJourney: boolean;
  isRenewalsJourney: boolean;
}>`
  /* Vertical Dashed-Line */
  background-image: linear-gradient(to bottom, ${props => props.theme.colors.neutralGrey1} 50%, #6a2d87 40%);
  color: ${({ theme }) => theme.colors.primaryPurple};
  background-position: left;
  background-size: ${props => props.theme.pxToRem(1)} ${props => props.theme.pxToRem(20)};
  background-repeat: repeat-y;
  align-items: center;
  font-family: ${props => props.theme.fontFamily};
  font-weight: ${props => props.theme.fontWeights.regular};
  stroke-dasharray: 20 !important;
  ${props => props.theme.mediaQueries.desktopOnly} {
    height: ${getVerticalLineDesktop} !important;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {\
    height: ${getVerticalLineMobile} !important;
  }
  ${props => props.theme.mediaQueries.tabletOnly} {
    height: ${getVerticalLineTablet} !important;
  }
`;

const StyledLi = styled.ul<StyledLiProps>`
  padding-left: ${props => props.theme.pxToRem(15)};
  ${({ changeColor }) =>
    changeColor &&
    css`
      color: ${props => props.theme.colors.primaryPurple};
    `}

  ${({ isItemDisabled }) =>
    isItemDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

const StyledDivider = styled.div<{
  activeIndex: number;
  index: number;
  changeColor: boolean;
}>`
  width: 100%;
  border-radius: ${props => props.theme.pxToRem(4)};
  background: linear-gradient(87deg, ${({ theme }) => theme.colors.primaryPurple}, #9c2463 100%) !important;
  height: ${props => props.theme.pxToRem(1)};
  margin-top: ${props => props.theme.pxToRem(8)};
  .white-divider & {
    background: ${props =>
      `${props.changeColor ? props.theme.colors.neutralBlack : props.theme.colors.primaryPurple}`} !important;
    opacity: 1 !important;
  }
`;

const StyledNumbering = styled.span`
  background-image: linear-gradient(85deg, #6a2d87 35%, #a93d69 101%);
  font-family: 'Roboto';
  height: ${props => props.theme.pxToRem(48)};
  width: ${props => props.theme.pxToRem(48)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.theme.pxToRem(5)};
  color: ${props => props.theme.colors.neutralWhite};
  font-size: ${props => props.theme.fontSizes.xl}
  font-weight: ${props => props.theme.fontWeights.bold};
  margin-left: ${props => props.theme.pxToRem(-64)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin-left: ${props => props.theme.pxToRem(-56)};
    height: ${props => props.theme.pxToRem(33)};
    width: ${props => props.theme.pxToRem(33)};
    font-size: ${props => props.theme.fontSizes.m}
  }
  flex-shrink: 0;
`;

const StyledSpan = styled.div`
  font-family: 'Roboto';
  font-size: ${props => props.theme.fontSizes.l};
  ${props => props.theme.mediaQueries.mobileOnly} {
    font-size: ${props => props.theme.pxToRem(20)};
    display: inline-block;
  }
`;

const StyledSpanChoose = styled.span`
  text-align: left;
  font-family: 'Roboto';
  font-weight: ${props => props.theme.fontWeights.medium};
`;

const InfoPillDiv = styled.div<{ hasCenterRenewalIssuer: boolean }>`
  margin-left: ${props => (props.hasCenterRenewalIssuer ? props.theme.pxToRem(620) : props.theme.pxToRem(750))};
  display: flex;
  gap: ${props => props.theme.pxToRem(20)};
`;

const StyledSpanPill = styled.span`
  margin-top: ${props => props.theme.pxToRem(-7)};
`;
const StyledSpanPillOutSide = styled.span`
  display: inline-flex;
  margin-top: ${props => props.theme.pxToRem(7)};
`;

const InfoPill = styled.span<{ isCenterMembershipRenewal?: boolean; isFlpPackage?: boolean; index: number }>`
  margin: ${props => props.theme.pxToRem(-30)} ${props => props.theme.pxToRem(210)};
  width: fit-content;
  height: ${props => props.theme.pxToRem(32)};
  padding: ${props => (!props.isFlpPackage ? props.theme.pxToRem(12) : props.theme.pxToRem(5))};
  font-size: ${props => props.theme.fontSizes.xs};
  text-transform: ${props => !props.isCenterMembershipRenewal && `capitalize !important`};
  font-weight: ${props =>
    props.isCenterMembershipRenewal ? props.theme.fontWeights.medium : props.theme.fontWeights.regular};
  line-height: ${props => (!props.isFlpPackage ? props.theme.pxToRem(1.5) : props.theme.pxToRem(10))};
  text-align: center;
  color: ${({ theme }) => theme.colors.neutralGrey6};
  background-color: ${({ theme }) => theme.colors.neutralWhite};
  border-radius: ${props => props.theme.pxToRem(16)};
  border-style: solid;
  border-width: ${props => props.theme.pxToRem(1)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  ${props => props.theme.mediaQueries.desktopOnly} {
    margin-left: ${props => `${props.index === 3 ? props.theme.pxToRem(560) : props.theme.pxToRem(280)}`} !important;
  }
  ${props => props.theme.mediaQueries.mobileOnly} {
    margin: ${props =>
      `${props.theme.pxToRem(20)} ${props.theme.pxToRem(1)} ${props.theme.pxToRem(-40)} ${props.theme.pxToRem(18)}}`};
    padding: ${props =>
      `${props.theme.pxToRem(5)} ${props.theme.pxToRem(12)} ${props.theme.pxToRem(5)} ${props.theme.pxToRem(20)}`};
    display: flex;
  }
`;

const StyledAccordionTitle = styled(Accordion.Title)<{ activeIndex: number; index: number; active: boolean }>`
  color: ${props => (props.active ? props.theme.colors.primaryPurple : props.theme.colors.neutralBlack)} !important;
  font-size: ${props => props.theme.fontSizes.l} !important;
  font-weight: ${props => props.theme.fontWeights.regular};
  justify-content: space-between;
  flex-grow: 1;
  ${props => props.theme.mediaQueries.mobileOnly} {
    text-align: left !important;
    font-weight: ${props => (props.active ? props.theme.fontWeights.medium : props.theme.fontWeights.regular)};
    &&&&&&& {
      padding-right: 0;
    }
  }
  ${props => props.theme.mediaQueries.tabletMin} {
    padding-right: 0 !important;
  }
  &&&&&& {
    cursor: ${props => `${props.isItemDisabled} ? not-allowed : pointer`};
    padding: ${props =>
      `${props.theme.pxToRem(6)} ${props.theme.pxToRem(96)} ${props.theme.pxToRem(8)} ${props.theme.pxToRem(32)}`};
  }
`;

const StyledDivFLP = styled.div`
  margin-top: ${props => props.theme.pxToRem(200)};
`;

const StyledCenterDescription = styled.p`
  text-align: left;
  line-height: 1.33;
  margin-top: ${props => props.theme.pxToRem(-50)};
  color: ${props => props.theme.colors.neutralBlack};
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.fontSizes.m};
  padding-left: ${props => props.theme.pxToRem(12)};
`;

const StyledAccordionHeaderContainer = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.pxToRem(64)};
`;
const StyledAccordionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledAccordionContent = styled(Accordion.Content)`
  margin-top: ${props => props.theme.pxToRem(-48)};
  margin-bottom: ${props => props.theme.pxToRem(64)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
  }
`;

const StyledHeaderAccordionIcon = styled.img`
  cursor: pointer;
  height: ${props => props.theme.pxToRem(19)};
  &:hover {
    opacity: 0.5;
  }
`;

const StyledHeaderSlugCloseIcon = styled.img`
  padding-left: ${props => `${props.theme.pxToRem(5)}`};
  margin-top: ${props => props.theme.pxToRem(-1)};
`;

const StyledMembershipInfo = styled.div`
  position: relative;
  margin: ${props => `0 0 ${props.theme.pxToRem(15)} ${props.theme.pxToRem(15)}`};
  font-size: ${props => props.theme.fontSizes.s};
  color: ${props => `${props.theme.colors.neutralGrey7}`};

  ${props => props.theme.mediaQueries.mobileOnly} {
    display: flex;
    justify-content: flex-end;
    text-align: left;
  }

  ${props => props.theme.mediaQueries.desktopOnly} {
    bottom: ${props => `${props.theme.pxToRem(10)}`};
  }
`;

const StyledInfoBubble = styled.img`
  margin-left: ${props => `${props.theme.pxToRem(3)}`};
  position: absolute;
  bottom: ${props => `${props.theme.pxToRem(1)}`};
  fill: ${props => props.theme.colors.primaryPurple};
`;

const ActionButtonsContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-top: ${props => props.theme.pxToRem(42)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    width: 100%;
    flex-direction: column;
    margin-top: ${props => props.theme.pxToRem(24)};
  }

  &&&& {
    button {
      ${props => props.theme.mediaQueries.desktopOnly} {
        :first-child {
          margin-right: ${props => props.theme.pxToRem(27)};
          padding: ${props => `${props.theme.pxToRem(14)} ${props.theme.pxToRem(86)}`};
        }
        :last-child {
          margin-left: ${props => props.theme.pxToRem(27)};
          padding: ${props => `${props.theme.pxToRem(14)} ${props.theme.pxToRem(113)}`};
        }
      }
      ${props => props.theme.mediaQueries.mobileOnly} {
        width: 100%;
        margin-top: ${props => props.theme.pxToRem(10)};
      }
    }
  }
`;

const StyledContainer = styled(Container)`
  &&&&& {
    width: ${props => props.theme.pxToRem(1280)};
    margin: 0 ${props => props.theme.pxToRem(30)};
    ${props => props.theme.mediaQueries.mobileOnly} {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
`;
